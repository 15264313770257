.login-step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-step-radio-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-step-radio-wrapper {
  min-width: 480px;
  min-height: 78px;
  background-color: rgba(236, 236, 236, 0.43);
  border-radius: 7px;
  margin: 10px;
  font-size: 26px;
  color: rgba(4, 35, 66, 0.5);
  padding: 23px 25px;
}
.box .auth-title {
  font-size: 50px;
}
.skip-or-login {
  margin: 2em;
}

.skip-or-login > a {
  margin: 1em;
}

.email-input {
  width: 30%;
  margin: 0em auto;
}

@media only screen and (max-width: 500px) {
  .login-step-radio-container {
    width: 100%;
  }

  .login-step-radio-wrapper {
    min-width: 300px;
  }

  .box .auth-title {
    font-size: 30px;
  }

  .email-input{
    width: 90%;
  }
}
