/* Car-details index.css */

.selecttype {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.center-box {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}

.list {
  width: 60%;
  margin: auto;
  margin-top: 2em;
}

.next-btn {
  margin-top: 2rem;
}

.input-wrapper {
  width: 35%;
  margin: auto;
}

.outlined-box {
  border: 1px solid black;
  border-radius: 5px;
  padding: 1em;
}

.actions {
  width: max-content;
  margin: 0em auto;
}

.dateinputs {
  width: 60%;
  margin: auto;
  display: flex;
  text-align: left;
  justify-content: space-around;
}

.choices {
  margin: auto;
  margin-top: 2em;
  text-align: left;
  display: flex;
  align-items: center;
}

.choice {
  width: max-content;
  margin: 2em;
}

.question {
  font-weight: bold;
  color: #a2afba;
}

.get-btn {
  margin-top: 3em;
}

.tab-wrapper {
  width: 30%;
  margin: auto;
}

.subheading {
  color: gray;
  font-weight: 400;
}

@media only screen and (max-width: 768px) {
  .tab-wrapper {
    width: 90%;
  }

  .input-wrapper {
    width: 90%;
  }

  .list {
    width: 95%;
    height: max-content;
  }

  .center-box {
    margin-top: 5em;
  }

  .center-box h1 {
    width: 95%;
    margin: auto;
    /* margin-top: 3em; */
    margin-bottom: 1em;
  }

  .dateinputs {
    max-width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 5em;
  }

  .choices {
    width: 95%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .choice {
    max-width: 95%;
    margin: auto;
  }
}
