.card {
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px lightgrey;
  width: 100%;
  height: 100%;
  display: inherit;
  flex-direction: inherit;
}

.tile {
  border: 1px solid gray;
  color: gray;
  font-weight: 500;
  padding: 0.5em 1em;
  width: max-content;
  border-radius: 5px;
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-duration: 0.25s;
}

.tile:hover {
  border: 1px solid #f4b118;
  color: #f4b118;
}

.image-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5em;
  /* font-weight: 500; */
  width: max-content;
}

.image-tile p{
  line-height: 20px;
}
/* 
.image-tile:hover {
  border: 1px solid #f4b118;
  color: #f4b118;
} */

.inputwithlabel {
  width: 100%;
  /* margin-left: 0.5em;
  margin-right: 0.5em; */
}

.label {
  font-size: medium;
  margin-bottom: 0.5em;
  color: #a2afba;
}

.field {
  color: #a2afba;
  font-weight: 500;
}

.product-column {
  /* border: 1px solid red; */
  width: 100%;
  padding-left: 2em;
}

.product-name {
  font-weight: 600;
  font-size: 20px;
}

.comparison {
  width: 95%;
  margin: auto;
}

.comparison-table {
  text-align: left;
}

.comparison-table td,
th {
  margin: 0;
  white-space: nowrap;
  border-top-width: 0px;
  min-width: 350px;
  padding-left: 1em;
  padding-right: 1em;
}

.comparison-table-outer-div {
  width: 1150px;
  margin: auto;
  overflow-x: scroll;
  margin-left: 300px;
  overflow-y: visible;
  padding: 0;
}

.headcol {
  position: absolute;
  width: 300px;
  left: 2.5%;
  top: auto;
  color: #a2afba;
}

.table-info-cell {
  min-width: 350px;
}

.table-prop-cell {
  text-align: center;
}

.table-prop-row .headcol {
  padding-top: 1em;
}

.table-prop-row .table-prop-cell {
  padding-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid #a2afba;
}

.horizontal-divider-wrapper {
  display: none;
}

@media only screen and (max-width: 1080px) {
  .image-tile img {
    width: 50px;
  }

  .vertical-divider-wrapper {
    display: none;
  }

  .horizontal-divider-wrapper {
    width: 100%;
    display: block;
  }

  .table-prop-row {
    display: none;
  }

  .headcol {
    display: none;
    width: 0px;
    left: 0%;
  }

  .comparison-table-outer-div {
    max-width: 100vw;
    margin-left: 0px;
  }
}

.comparison-table .ant-card-bordered{
  border: none !important;
}

.comparison-table .ant-card-body{
  padding: 0px !important;
}