.App {
  text-align: center;
  width: 100%;
  height: 100%;
}

.underlined-link {
  border-bottom: 1px solid #ffb200;
}

.light-text {
  color: #a2afba;
}

.large-hightlight-text {
  color: #ffb200;
  font-weight: 600;
  font-size: xx-large;
}

.blue-button-yellow-hover {
  padding: 0.5em 2em;
  border-radius: 5px;
  background-color: #042342;
  color: white;
  border: 1px solid #042342;
  text-align: center;
}

.blue-button-yellow-hover:hover {
  border: 1px solid #ffb200;
  background-color: #ffb200;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper{
  margin-left: 0px !important;
}
/* 
*::selection {
  background: #FFB200 !important;
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox .ant-checkbox-checked:hover .ant-checkbox-inner
{
  background-color: #FFB200 !important;
  border-color: #FFB200 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #FFB200 !important;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: #FFB200 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #FFB200 !important;
}
.ant-tabs-tab-btn:hover {
  color: #FFB200 !important;
}

.ant-tabs-tab-btn::selection {
  color: #FFB200 !important;
}

.ant-tabs-tab:hover {
  color: #FFB200 !important;
}

.ant-select-selector:hover {
  border-color: #FFB200 !important;
} */
