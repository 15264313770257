.car-insurance-result {
  width: 100%;
}

.car-summary-header {
  width: 95%;
  margin: auto;
  text-align: left;
}

.car-summary-header h1 {
  padding-top: 2em;
}

.car-summary {
  width: 100%;
}

.ant-card-meta-description {
  color: #ffb200;
}

.action-btn button {
  width: 100%;
  margin: auto;
  margin-top: 1em;
  text-align: center;
}

.filters-section {
  width: 95%;
  margin: auto;
  margin-top: 2em;
}

.filter-row {
  margin: auto;
  text-align: left;
}

.mail-btn {
  margin: 2em;
  padding: 2em;
}

@media only screen and (max-width: 1080px) {
  .car-summary {
    text-align: center;
    border: 1px solid gray;
    border-radius: 5px;
  }

  .car-summary-header h1 {
    padding-top: 3em;
  }
}
