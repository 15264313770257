.ant-input .ant-input-lg:hover {
  border: 1px solid #FFB200;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #FFB200 !important;
}

::selection {
  background: #FFB200 !important;
}

.ant-input-affix-wrapper{
    padding: 1em !important;
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  box-shadow: 0 0 0 2px rgba(244, 178, 24, 0.247) !important;
  border-color: rgb(244, 177, 24) !important;
}