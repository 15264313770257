.car-praposal-parent-div {
  width: 100%;
  text-align: left;
  padding: 1em;
}

.car-praposal-summary-header {
  height: max-content;
  margin: auto;
  margin-top: 4em;
}

.car-praposal-details-section {
  width: 95%;
  margin: auto;
}

.userdetails {
  width: 100%;
}

.form {
  width: 100%;
}

.ack {
  display: block;
}

.header-product-image{
  width: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 768px) {
  .car-praposal-details-section {
    width: 100%;
  }
}
