.box {
  display: flex;
  width: 100%;
  min-height: 100vh;
  justify-content: center;
  flex-direction: column;
}

.home {
  display: flex;
  width: 100%;
  min-height: 85vh;
  max-height: max-content;
  justify-content: center;
  flex-direction: column;
  position: relative;
  top: 5em;
}

.home .logo-img {
  margin-bottom: 5em;
}

.box h1,
.home h1 {
  font-size: 50px;
  color: #042342;
  font-weight: bolder;
  line-height: normal;
}

.box img,
.home img {
  margin: auto;
}

.box h2,
.home h2 {
  color: #a2afba;
}

.btn {
  margin-top: 2em;
}

.anim {
  width: 300px;
  margin: 3em auto;
}

.ins-tag {
  color: #a2afba;
}

.insurance-choices {
  width: 70%;
  margin: 0em auto;
}

.ins-choice-item {
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em;
}

.ins-choice-item img {
  width: 100px;
}

.products-section {
  height: max-content;
  margin-top: 8em;
}

.insurance-options-wrapper {
  min-width: 100%;
  margin: auto;
  margin-top: 4em;
  height: max-content;
  display: flex;
  width: max-content;
  justify-content: space-around;
  padding: 3em 2em;
  background: linear-gradient(to bottom,  white 0%,white 50%,#042342 50%,#042342 100%);
}

.ins-card {
  width: 180px;
  color: #a2afba;
  border-radius: 10px;
  border: 2px solid #a2afba;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
  transition-duration: 0.5s;
  margin: 2em;
}

.ins-card:hover {
  color: #042342;
  border: 2px solid #ffb200;
}

.ins-type {
  color: #042342;
  font-size: large;
}

.ins-card:hover .ins-subtitle {
  color: #ffb200;
}

.ins-card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
}

.price-btn {
  color: white;
  background-color: #a2afba;
  border: 1px solid #a2afba;
  border-radius: 5px;
  padding: 0.5em 1em;
  cursor: pointer;
}

.ins-card:hover .price-btn {
  background-color: #042342;
  font-weight: 500;
  border-color: #042342;
}

.reviews-ratings-wrapper {
  width: 100%;
  background-color: #f7f7f7;
  min-height: 50vh;
  padding: 2em;
}

.featured-image img {
  width: 80%;
  margin-bottom: 2em;
}

.reviews-ratings-wrapper p {
  width: 40%;
  margin: auto;
  color: #042342;
}

.ratings {
  height: 100px;
  width: 40%;
  display: flex;
  justify-content: space-around;
  margin: auto;
  margin-top: 4em;
}

.reviews-section {
  display: flex;
  max-width: 100%;
  overflow-x: scroll;
  padding: 2em;
}

.review-card {
  background-color: white;
  width: max-content;
  min-width: 25%;
  padding: 1em;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0px 0px 10px 1px lightgray;
  margin: 0 2em;
}

.reviewer {
  display: flex;
  text-align: left;
  align-items: center;
}

.reviewer span {
  margin-right: 1em;
}

.ant-card-meta-title {
  margin-bottom: 1px !important;
}

.switching-data-section{
  margin-top: 8em;
}

.switching-data-section p{
  color: #a2afba;
  font-size: larger;
}

.switching-data-section img{
  width: 40%;
  margin: 5em auto;
}

.insurance-partners-section{
  margin-top: 4em;
  min-height: max-content;
  padding: 3em auto;
}

.insurance-partners{
  width: 90%;
  margin: 5em auto;
  display: flex;
  object-fit: cover;
  justify-content: space-between;
}

.insurance-partners img{
  width: 15%;
}

@media only screen and (max-width: 768px) {
  .insurance-options-wrapper {
    flex-direction: column;
    align-items: center;
    background: none;
  }

  .home .logo-img {
    margin-top: 5em;
    margin-bottom: 3em;
    position: sticky;
    top: 0;
    z-index: 25;
  }

  .box h1,
  .home h1 {
    width: 90%;
    margin: 1em auto;
    font-size: 30px;
    color: #042342;
    font-weight: bolder;
    line-height: normal;
  }

  .ins-choice-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
  }

  .ins-choice-item {
    min-height: max-content;
    min-width: max-content;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }

  .insurance-options-wrapper {
    min-height: max-content;
  }

  .ins-choice-item img {
    width: 60px;
    object-fit: fill;
  }

  .reviews-ratings-wrapper p {
    width: 90%;
    margin: auto;
  }

  .review-card {
    min-width: 95%;
  }

  .ratings {
    width: 95%;
  }

  .ratings span {
    font-size: 40px !important;
  }

  .switching-data-section img{
    width: 80%;
    margin: 5em auto;
  }
}

@media only screen and (max-width: 1080px) and (min-width: 768px) {
  .insurance-options-wrapper {
    display: grid;
    grid-template-columns: auto auto auto;
    row-gap: 2em;
    column-gap: 2em;
  }
}
