.car-details {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.content {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.actions {
  display: flex;
}

.next-btn {
  margin: 1em;
}

.prev-btn{
  margin: 1em;
  display: inline;
}
