.navbar {
  width: 100%;
  height: max-content;
  position: fixed;
  padding-top: 0.5em;
  z-index: 20;
  background-color: white;
  display: flex;
  justify-content: space-between;
}

.menu-container {
  width: 50%;
}

a,
a:hover {
  color: #1c3853;
}

.nav-logo {
  max-width: 150px;
  float: left;
}

.hamburger-btn {
  display: none;
}

.left-menu {
  width: 50%;
}

.right-menu {
  float: right;
}

.ant-menu-horizontal{
  border-bottom-style: none !important;
}

@media only screen and (max-width: 800px) {
  .navbar {
    padding: 1em;
    padding-left: 0.5em;
  }

  .left-menu,
  .right-menu {
    display: none !important;
  }

  .menu-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 1em;
    align-items: center;
  }

  .hamburger-btn {
    display: block; 
  }
}
