.footer {
  min-width: 100%;
  background-color: #042342;
  min-height: max-content;
  padding: 2em;
  color: white;
  text-align: left;
}

.footer-content {
  width: 90%;
  margin: auto;
}

.footer-title {
  color: lightgray;
  padding-top: 10px;
  display: block;
}

.links-container {
  padding-top: 1em;
}
.social-media {
  margin-top: 2em;
}

.social-media a {
  display: inline;
  margin-right: 1em;
  color: white;
}

.links-container a,
.links-container a:hover {
  color: white;
  display: block;
  margin: 0.5em 0em;
}

.footer-logo {
  width: 50%;
}
